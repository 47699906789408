import React from 'react';
import ActionCard from 'blocks/ActionCard';
import FullscreenLogin from 'blocks/FullscreenLogin';
import Layout from 'blocks/Layout';
import OUSintro from 'blocks/OUSwelcome';
import cx from 'classnames';
import Block from 'components/Block';
import Button from 'components/Button';
import CircularProgress from 'components/CircularProgress';
import Container from 'components/Container';
import DjediContent from 'components/DjediContent';
import FeatureFlag from 'components/FeatureFlag';
import HashLink from 'components/HashLink';
import Link from 'components/Link';
import Swoosh from 'components/Swoosh';
import SwooshBlock from 'components/SwooshBlock';
import Typography from 'components/Typography';
import AppContext from 'containers/AppContext';
import NODES, { STEPS } from 'djedi-nodes/home';
import TIMESLOTS_NODES from 'djedi-nodes/timeslots';
import { ForceNodes } from 'djedi-react';
import { NextPage } from 'next';
import getConfig from 'next/config';
import NextLink from 'next/link';
import Faq from 'partials/Index/Faq';
import styles from 'partials/Index/Index.module.css';
import { citiesDataSource } from 'partials/Timeslots/data-sources/citiesDataSource';
import { servicesDataSource } from 'partials/Timeslots/data-sources/servicesDataSource';
import SearchTimeslotsForm from 'partials/Timeslots/SearchTimeslotsForm/SearchTimeslotsForm';
import { shouldFullscreenLogin } from 'utils';
import { HASHES, Market, MARKET_SCHOOLS, OUS_MARKETS } from 'utils/constants';
import { DataResult } from 'utils/data-sources';
import { ToggleableFeature } from 'utils/FeatureFlag/constants';

import ROUTES from '../../routes';
import { Service } from '../../types/Clinic';
import { DjediNodeState } from '../../types/DjediNode';
import { EnhancedPageContext } from '../../types/EnhancedPageContext';
import { FaqEntry } from '../../types/Lesson';
import { City } from '../../types/Timeslots';
import EmbeddedVideo from '../components/EmbeddedVideo';
import TrustpilotWidget from '../components/TrustpilotWidget';
import HarmonyPNG from '../icons/harmony.png';
import PhoneSVG from '../icons/local_phone.svg';
import LocationSVG from '../icons/location_on-outline.svg';
import LockSVG from '../icons/lock-outline.svg';
import RochePNG from '../icons/roche.png';

const Step: React.FC = props => <div className={styles.step} {...props} />;

const Index: NextPage<{
  questions: FaqEntry[];
  cities: DataResult<City[]>;
  services: DataResult<Service[]>;
}> = ({ questions, cities, services }) => {
  const { loginState, currentMarket } = React.useContext(AppContext);

  const fullscreenLogin = shouldFullscreenLogin(currentMarket);

  // special case for temporairy OUS market
  if (currentMarket === Market.OUS) return <OUSintro />;

  // special case for fullscreen login
  if (fullscreenLogin) return <FullscreenLogin />;

  const intro = (
    <div className={styles.introContainer}>
      {!MARKET_SCHOOLS.includes(currentMarket) && <TrustpilotWidget />}
      <Typography component="h1" variant="hero">
        {NODES.INTRO_TITLE}
      </Typography>
      <Typography variant="md" component="div">
        {NODES.INTRO_CONTENT}
      </Typography>
      {currentMarket === Market.NO ? (
        <div className={styles.searchTimeslotsFormContainer}>
          <Typography variant="strong">{NODES.SEARCH_TIMESLOTS_TITLE}</Typography>
          <Block>
            <SearchTimeslotsForm cities={cities} services={services} />
          </Block>
        </div>
      ) : (
        <div className={styles.buttonContainer}>
          {currentMarket === Market.SE ? (
            <DjediContent
              node={NODES.HEADER_CTA_LINK}
              render={s => (
                <NextLink href={s.content.props.children} shallow passHref>
                  <Button>{NODES.HEADER_CTA}</Button>
                </NextLink>
              )}
            />
          ) : (
            <HashLink hash={HASHES.LOGIN}>
              <Button>{NODES.HEADER_CTA}</Button>
            </HashLink>
          )}
          <FeatureFlag id={ToggleableFeature.CLINIC_ACTION}>
            <NextLink {...ROUTES.CLINICS} passHref>
              <Button variant="outlined">{NODES.CLINICS_CTA}</Button>
            </NextLink>
          </FeatureFlag>
        </div>
      )}
    </div>
  );

  return (
    <Layout
      title={NODES.META_TITLE}
      description={NODES.META_DESCRIPTION}
      NavProps={{ color: 'white', responsiveColor: 'pink' }}
    >
      <Swoosh from="pink" to="white" className={styles.swoosh} />
      <div className={styles.desktopOnly}>
        <SwooshBlock
          mirrorY
          position="bottom"
          className={cx({
            // SearchTimeslotsForm date picker overflows vertically
            [styles.overflowYSwooshBlock]: currentMarket === Market.NO,
          })}
        >
          <div className={styles.desktopIntro}>
            {intro}
            {NODES.PHONE_IMAGE}
          </div>
        </SwooshBlock>
      </div>
      <div className={styles.phoneOnly}>
        {currentMarket === Market.NO ? (
          <SwooshBlock
            className={styles.swooshMobileBlock}
            from="white"
            to="pink"
            mirrorY
            position="bottom"
          >
            <Block>
              <Container className={cx(styles.intro, styles.mobile)}>{intro}</Container>
            </Block>
          </SwooshBlock>
        ) : (
          <>
            <Block>
              <Container className={styles.intro}>{intro}</Container>
            </Block>
            <SwooshBlock from="white" to="pink" mirrorY position="center">
              <Container>{NODES.PHONE_IMAGE}</Container>
            </SwooshBlock>
          </>
        )}
      </div>
      <Block background="pink" padding="bottom-extra" component="article">
        <Container>
          <Typography variant="heading" component="h2">
            {NODES.HEADER_TITLE}
          </Typography>
          <Typography variant="md" component="div">
            {NODES.HEADER_CONTENT}
          </Typography>
        </Container>
      </Block>

      <Block background="white" component="article">
        {currentMarket === Market.NO ? (
          <>
            <Container className={styles.explanation}>
              <Container className={styles.innerExplanation}>{NODES.EXPLANATION_POSTER}</Container>
              <br />
              <div className={styles.pointsWrapper}>{NODES.EXPLANATION_POINTS}</div>
              <br />
              <Typography variant="heading" component="h2">
                {NODES.EXPLANATION_TITLE}
              </Typography>
              <Typography variant="md" component="div">
                {NODES.EXPLANATION_CONTENT}
              </Typography>
              <br />
              <img src={HarmonyPNG.src} alt="Harmony Logo" className={styles.harmonyLogo} />
              <img src={RochePNG.src} alt="Roche Logo" className={styles.rocheLogo} />
            </Container>
            <Container className={styles.explanation}>
              <Container className={styles.innerExplanation}>
                {NODES.SERVICES_EXPLANATION_POSTER}
              </Container>
              <Typography variant="heading" component="h2">
                {NODES.SERVICES_EXPLANATION_TITLE}
              </Typography>
              <Typography variant="md" component="div">
                {NODES.SERVICES_EXPLANATION_CONTENT}
              </Typography>
            </Container>
          </>
        ) : (
          <Container>
            {React.cloneElement(NODES.EXPLANATION_VIDEO_URL, {
              edit: false,
              render: (state: DjediNodeState) => {
                if (!state || state.type !== 'success' || !state.content) {
                  return null;
                }
                return (
                  <EmbeddedVideo
                    videoEmbedUrl={state.content}
                    coverImage={NODES.EXPLANATION_VIDEO_POSTER}
                  />
                );
              },
            })}

            <br />
            <Typography variant="heading" component="h2">
              {NODES.EXPLANATION_TITLE}
            </Typography>
            <Typography variant="md" component="div">
              {NODES.EXPLANATION_CONTENT}
            </Typography>
          </Container>
        )}
      </Block>
      <Block background="powderpink" component="article">
        <Container desktop>
          <Typography variant="heading" component="h2" align="center">
            {NODES.STEPS_TITLE}
          </Typography>
          <Block className={styles.steps}>
            {STEPS.map((step, i) => (
              <Step key={i}>
                <CircularProgress progress={(1 / STEPS.length) * (i + 1)}>{i + 1}</CircularProgress>
                <Typography component="h3" variant="heading">
                  {step.TITLE}
                </Typography>
                <Typography variant="md" component="div">
                  {step.CONTENT}
                </Typography>
                <DjediContent
                  node={step.CTA_LINK}
                  render={s => (
                    <div className={styles.cta}>
                      <NextLink href={s.content.props.children} shallow passHref>
                        <Button block variant="outlined">
                          {step.CTA_TEXT}
                        </Button>
                      </NextLink>
                    </div>
                  )}
                />
              </Step>
            ))}
          </Block>
        </Container>
      </Block>
      <Block component="article">
        <Container>
          <Typography variant="heading" component="h3">
            {NODES.ABOUT_TITLE}
          </Typography>
          <Typography variant="md" component="div">
            {NODES.ABOUT_CONTENT}
          </Typography>
          <br />
          <DjediContent
            node={NODES.ABOUT_URL}
            render={s => (
              <Link href={s.content.props.children} color="denim" passHref>
                {NODES.ABOUT_LINK}
              </Link>
            )}
          />
        </Container>
      </Block>
      <Block padding="none">
        <Container desktop>
          <div className={styles.actionCards}>
            <FeatureFlag id={ToggleableFeature.CLINIC_ACTION}>
              <ActionCard icon={LocationSVG} title={NODES.PROXIMITY_TITLE}>
                <Typography variant="md" component="div">
                  {NODES.PROXIMITY_CONTENT}
                </Typography>
                <div className={styles.actionsCta}>
                  <DjediContent
                    node={NODES.CLINICS_URL}
                    render={s => (
                      <NextLink href={s.content.props.children} passHref>
                        <Button block>{NODES.CLINICS_CTA}</Button>
                      </NextLink>
                    )}
                  />
                </div>
              </ActionCard>
            </FeatureFlag>
            <ActionCard icon={LockSVG} title={NODES.SECURITY_TITLE}>
              <Typography variant="md" component="div">
                {NODES.SECURITY_CONTENT}
              </Typography>
              {!loginState.loggedIn && (
                <div className={styles.actionsCta}>
                  <NextLink href="/#login" shallow passHref>
                    <Button block>{NODES.SECURITY_CTA}</Button>
                  </NextLink>
                </div>
              )}
            </ActionCard>
            <FeatureFlag id={ToggleableFeature.PHONE_ACTION}>
              <ActionCard icon={PhoneSVG} title={NODES.PHONE_TITLE}>
                <Typography variant="md" component="div">
                  {NODES.PHONE_CONTENT}
                </Typography>

                <div className={styles.actionsCta}>
                  <DjediContent
                    node={NODES.PHONE_EMAIL_ADDRESS}
                    render={s => (
                      <Button href={`mailto:${s.content.props.children}`} block variant="outlined">
                        {NODES.PHONE_CTA}
                      </Button>
                    )}
                  />
                </div>
              </ActionCard>
            </FeatureFlag>
          </div>
        </Container>
      </Block>
      <Faq questions={questions} />
      <ForceNodes>
        {NODES}
        {STEPS}
        {/** Used in `SearchTimeslotsForm`. */}
        {/** (Maybe make separation djedi-nodes/search-timeslots and djedi-nodes/timeslots?) */}
        {TIMESLOTS_NODES}
      </ForceNodes>
    </Layout>
  );
};

Index.getInitialProps = async function (ctx: EnhancedPageContext) {
  const { api, res, user } = ctx;
  const { publicRuntimeConfig } = getConfig();
  const currentMarket = Market[publicRuntimeConfig.MARKET];

  // routing to / on OUS subdomains should redirect to main site
  if (currentMarket !== Market.OUS && OUS_MARKETS.includes(currentMarket)) {
    const location = user ? `/dashboard` : publicRuntimeConfig.OUS_URL;

    if (!!res) {
      res.statusCode = 302;
      res.setHeader('Location', location);
    }
    if (typeof window !== 'undefined') {
      window.location.href = location;
    }
  }

  const [questions, cities, services] = await Promise.all([
    api.getFaq(),
    citiesDataSource.get(api),
    servicesDataSource.get(api),
  ]);

  return {
    questions: questions || [],
    cities,
    services,
  };
};
export default Index;

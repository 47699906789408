import React, { HTMLProps } from 'react';
import cx from 'classnames';
import useResizeObserver from 'use-resize-observer';

import styles from './RawExpandable.module.css';

export interface ExpandableProps extends HTMLProps<HTMLDivElement> {
  open: boolean;
  contentPadding?: boolean;
}

/**
 * A simple expandable
 */
const RawExpandable = ({
  open,
  className,
  children,
  contentPadding,
  style,
  ...rest
}: ExpandableProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  // To prevent bubbling to see if the transitionEnd event really is for the parent
  const refContainer = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState(0);

  // internalOpen might seem weird, but it's needed to make sure open and transition are
  // set in sync, otherwise the transition will not work
  const [internalOpen, setInternalOpen] = React.useState(open);

  // If we're not transitioning there shouldn't be any animation on the height, since the children might animate height themselves
  const [transitioning, setTransitioning] = React.useState(false);

  // Don't capture height on open state change, since children can change height dynamically
  useResizeObserver({
    ref,
    onResize: () => {
      if (ref.current) {
        setHeight(ref.current.clientHeight);
      }
    },
  });

  React.useEffect(() => {
    setInternalOpen(open);
    setTransitioning(true);
    !open ? ref.current?.setAttribute('inert', '') : ref.current?.removeAttribute('inert');
  }, [open]);

  return (
    <div
      ref={refContainer}
      className={cx(styles.root, transitioning && styles.transition, className)}
      style={{
        ...style,
        height: internalOpen ? height : 0,
        transition: transitioning ? 'height 0.3s' : undefined,
      }}
      // onTransitionEnd fires for children of the element, so we need to check if the event target is this node
      // we could also use a stopPropagation, but that would require us to add that to every child that has a transition, not great :)
      onTransitionEnd={e => {
        const isMe = (e.target as undefined | HTMLElement)?.isSameNode(refContainer.current);

        if (isMe) {
          setTransitioning(false);
        }
      }}
      {...rest}
    >
      <div aria-hidden={internalOpen} ref={ref}>
        <div className={cx(styles.content, contentPadding === false && styles.noPadding)}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default RawExpandable;

import React from 'react';
import cx from 'classnames';

import ExpandSVG from '../../icons/expand_more.svg';
import RawExpandable from '../RawExpandable';
import styles from './Expandable.module.css';

export interface ExpandableProps {
  disabled?: boolean;
  children: React.ReactNode;
  open?: boolean;
  className?: string;
  title: string | React.ReactNode;
  contentPadding?: boolean;
}

const Expandable: React.FC<ExpandableProps> = ({
  title,
  className,
  open: initialOpen = false,
  contentPadding = false,
  children,
  ...rest
}) => {
  const [open, setOpen] = React.useState(initialOpen);

  return (
    <div className={cx(styles.root, className)} {...rest}>
      <button
        aria-expanded={open}
        className={styles.opener}
        type="button"
        onClick={() => setOpen(!open)}
      >
        {typeof title === 'string' ? <span className={styles.title}>{title}</span> : title}
        <ExpandSVG aria-hidden="true" fill="currentColor" className={styles.indicator} />
      </button>
      <RawExpandable contentPadding={contentPadding} open={open} className={styles.content}>
        {children}
      </RawExpandable>
    </div>
  );
};

export default Expandable;
